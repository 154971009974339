<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/education" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Yorumlar</h3>
      </div>
      <div class="card-body">
        <div class="item2-gl">
          <div class="tab-content">
            <div class="tab-pane active" id="tab-11">
              <div class="text-center" v-if="list.length == 0">
                <h4>Yorum Yok</h4>
              </div>
              <div
                class="card overflow-hidden"
                v-for="(item, i) in list"
                :key="'comment-' + i"
              >
                <div class="d-md-flex">
                  <div class="card border-0 mb-0">
                    <div class="card-body">
                      <div class="item-card9">
                        <a href="jobs.html">{{ item.fullname }}</a>
                        <a href="jobs.html" class="text-dark"
                          ><h4 class="font-weight-semibold mt-1">
                            Yorum Tarihi:{{ item.created_at }}
                          </h4></a
                        >
                        <p class="mb-0 leading-tight">
                          İçerik:{{ item.content }}
                        </p>
                        <div class="card card-body m-2">
                          <div
                            v-for="(a, b) in item.sub_comment_list"
                            :key="'sub_comment_list' + b"
                          >
                            <h6>Yanıt Tarihi:{{ a.created_at }}</h6>
                            <span>İçerik:{{ a.content }}</span>
                            <hr />
                          </div>
                          <textarea
                            class="form-control mb-3 mt-3"
                            :name="'replyCommentContent' + i"
                            placeholder="Cevabınızı buraya yazabilirsiniz"
                          >
                          </textarea>
                          <button
                            type="button"
                            :id="'commentButton' + i"
                            @click="
                              replyToComment(item.id, i, item.education_id)
                            "
                            class="btn btn-primary"
                          >
                            Cevapla
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer py-4" v-if="item.approved == 0">
                      <div class="row">
                        <div class="col-6"></div>
                        <div class="col-6">
                          <div class="item-card9-footer d-flex">
                            <div class="ms-auto" >
                              <i class="side-menu__icon si si-clock"></i> Onay
                              Bekliyor
                              <div
                                class="block"
                                style="float: right; margin-left: 10px"
                              >
                                <button
                                  class="btn-sm btn btn-primary"
                                  :id="'approvedButton' + i"
                                  @click="
                                    approved_comment(item.id, i, item.education_id)
                                  "
                                >
                                  Onayla
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center-block text-center" v-if="list.length > 0">
          <Pagination
            :currentpage="currentpage"
            :pagesize="pagesize"
            :preload="'/profile/comments/'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Pagination from "../../../../components/Pagination.vue";
export default {
  created() {
    document.title = "Eğitim Yorumları";
    this.getList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
    };
  },
  methods: {
    getList() {
      let getProfileInfo = {
        education_id: this.$route.params.id,
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
      };
      this.$store
        .dispatch("getProfileEducationComment", getProfileInfo)
        .then((value) => {
          this.list = value.list;
          this.totalsize = value.size;
          this.pagesize = value.pages;
        });
    },
    replyToComment(id, index, user_id) {
      document.getElementById("commentButton" + index).disabled = true;
      document.getElementById("commentButton" + index).innerHTML =
        "Yorum Gönderiliyor..";
      let comment = document.querySelector(
        "textarea[name=replyCommentContent" + index + "]"
      ).value;
      if (comment == null || comment == "") {
        document.getElementById("commentButton" + index).disabled = false;
        document.getElementById("commentButton" + index).innerHTML = "Cevapla";
        this.$vToastify.warning("Yorum Boş Gönderilemez", "Uyarı!");
      } else {
        this.$store
          .dispatch("getEducationCommentSend", {
            education_id: user_id,
            comment: comment,
            parent_id: id,
          })
          .then((value) => {
            document.getElementById("commentButton" + index).disabled = false;
            document.getElementById("commentButton" + index).innerHTML =
              "Cevapla";
            this.getList();
          });
      }
    },
    approved_comment(id, index, education_id) {
      document.getElementById("approvedButton" + index).disabled = true;
      document.getElementById("approvedButton" + index).innerHTML =
        "Yorum Onaylanıyor..";

      this.$store
        .dispatch("approvedEducationComment", {
            id: id,
            education_id: education_id
        })
        .then((value) => {
          document.getElementById("approvedButton" + index).disabled = false;
          document.getElementById("approvedButton" + index).innerHTML =
            "Cevapla";
          this.getList();
        });
    },
    save() {
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Bilgiler kayıt ediliyor..";
      let about = document.querySelector("textarea[name=about]").value;
      let biographieSave = {
        about: about,
        skill: JSON.stringify(this.skillList),
        education: JSON.stringify(this.educationList),
      };
      this.$store.dispatch("biographieUpdate", biographieSave).then((value) => {
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Biyografimi Kaydet";
      });
    },
  },
  components: { Pagination },
};
</script>